import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../CommonComp/APIConstants';
import AddSupplier from './AddSupplier';

function ViewSupplier() {
    const { id } = useParams();
    const [supplier, setSupplier] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSupplierDetails = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${API_URL}/Supplier/GetSupplierById?id=${id}`);
                setSupplier(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchSupplierDetails();
    }, [id]);

    console.log("this is from view page",supplier)

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading supplier details</div>;

    return (
        <div>
            <AddSupplier supplierDetail={supplier} view={true} />
        </div>
    );
}

export default ViewSupplier;
