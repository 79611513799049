import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../CommonComp/APIConstants';
import AddSupplier from './AddSupplier';

function EditSupplier() {
    const { id } = useParams();
    const [supplier, setSupplier] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSupplierDetails = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${API_URL}/Supplier/GetSupplierById?id=${id}`);
                setSupplier(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchSupplierDetails();
    }, [id]);

    const handleFormSubmit = async (formData) => {
        const payload = {
            supplierId: formData.supplierId,
            supplierName: formData.supplierName,
            supplierCode: formData.supplierCode,
            gstNumber: formData.gstNumber,
            panNumber: formData.panNumber,
            phonenumber: formData.phonenumber,
            email: formData.email,
            active: formData.active,
        };

        try {
            await axios.post(`${API_URL}/Supplier/UpdateSupplierById`, payload);
            alert('Supplier updated successfully!');
        } catch (error) {
            console.error('Error updating supplier:', error);
            alert('Error updating supplier');
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading supplier details</div>;

    return (
        <div>
            <AddSupplier supplierDetail={supplier} isEdit={true} />
        </div>
    );
}

export default EditSupplier;
