import React, { forward, useState, useEffect, forwardRef } from 'react';
import axios from 'axios';
import './invoicespage.css';
import { API_URL } from '../../CommonComp/APIConstants';

const PrintInvoice = forwardRef(({ invoiceId, onReady }, ref) => {
    const [invoiceDetails, setInvoiceDetails] = useState([]);
    const [invoicePrintDetails, setInvoicePrintDetails] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchInvoiceDetails = async () => {
            try {
                console.log('Fetching invoice details for ID:', invoiceId);
                const response = await axios.get(API_URL + `/Invoice/GetInvoiceById?id=${invoiceId}`);
                const data = response.data;

                console.log('API Response:', data);

                setInvoiceDetails(data.invoiceDetails || []);
                setInvoicePrintDetails({
                    customerName: data.customerName,
                    addressLine1: data.addressLine1,
                    addressLine2: data.addressLine2,
                    city: data.city,
                    state: data.state,
                    contactNumber: data.contactNumber,
                    customerGST: data.customerGST,
                    customerPAN: data.customerPAN,
                    totalAmountGross: data.totalAmountGross,
                    totalDiscounAmount: data.totalDiscounAmount,
                    totalAmountNet: data.totalAmountNet
                });
                setLoading(false);
                if (onReady) {
                    onReady();
                }

            } catch (error) {
                console.error("Error fetching invoice details:", error);
                setLoading(false);
            }
        };

        if (invoiceId) {
            fetchInvoiceDetails();
        }
    }, [invoiceId, onReady]);

    if (loading) {
        return <div>Loading invoice details...</div>;
    }

    return (
        <div className="container my-1 mt-5" ref={ref} id='pdfPrint'>
            <div className="invoice-container">
                <table className="table table-bordered">
                    <thead className="thead-light">
                        <tr style={{ minWidth: "350px", fontSize: "10px" }}>
                            <th colSpan="5" className="text-center">Invoice</th>
                        </tr>
                        <tr style={{ minWidth: "350px", fontSize: "10px" }}>
                            <th colSpan="2" className="text-center">From (Seller)</th>
                            <th colSpan="3" className="text-center">To (Buyer)</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr style={{ minWidth: "350px", fontSize: "10px" }}>
                            <td className='py-3' colSpan="2" style={{ minWidth: "250px", fontSize: "10px" }}>
                                <div className='text-center border-bottom'>APAG Technology & Services Pvt Ltd.</div>
                                <div className='text-center border-bottom'>Bungalow No 5</div>
                                <div className='text-center border-bottom'>Godavari Hsg society No 1, Jail Road</div>
                                <div className='text-center border-bottom'>Nasik road, Nasik, Maharashtra - 422101</div>
                                <div className='text-center border-bottom'>Contact No - +91 9764889454</div>
                                <div className='border-bottom'>GST No: 27AAXAC6956F2ZZ</div>
                                <div>PAN No: AAXAC6956F</div>
                            </td>
                            <td className='py-3' colSpan="3" style={{ minWidth: "350px", fontSize: "10px" }}>
                                <div className='text-center border-bottom'>{invoicePrintDetails.customerName}</div>
                                <div className='text-center border-bottom'>{invoicePrintDetails.addressLine1}</div>
                                <div className='text-center border-bottom'>{invoicePrintDetails.addressLine2}</div>
                                <div className='text-center border-bottom'>{invoicePrintDetails.city}, {invoicePrintDetails.state}</div>
                                <div className='text-center border-bottom'>Contact - {invoicePrintDetails.contactNumber}</div>
                                <div className='border-bottom'>GST No: {invoicePrintDetails.customerGST}</div>
                                <div>PAN No: {invoicePrintDetails.customerPAN}</div>
                            </td>
                        </tr>
                        <tr style={{ minWidth: "350px", maxWidth: "350px", fontSize: "10px" }}>
                            <th className='text-center'>Sr. No.</th>
                            <th className='text-center'>Particular</th>
                            <th className='text-center'>Qty</th>
                            <th className='text-center'>Rate</th>
                            <th className='text-center'>Amt.</th>
                        </tr>
                        <tr  style={{ minWidth: "350px", fontSize: "10px" }}>
                                <td className='py-3'></td>
                                <th className='py-3 text-center'>Rsidential Database</th>
                                <td className='py-3'></td>
                                <td className='py-3'></td>
                                <td className='py-3'></td>
                            </tr>
                        {invoiceDetails.map((item, index) => (
                            <tr key={item.productId} style={{ minWidth: "350px", fontSize: "10px" }}>
                                <td className='py-3 text-right'>{index + 1}</td>
                                <td className='py-3'>{item.productName}</td>
                                <td className='py-3 text-right'>{item.quantity}</td>
                                <td className='py-3 text-right'>{item.unitPrice}</td>
                                <td className='py-3 text-right'>{item.totalAmount}</td>
                            </tr>
                        ))}
                        <tr style={{ minWidth: "350px", fontSize: "10px" }}>
                            <td colSpan="4" className="text-center py-3">Gross Value</td>
                            <td className="py-3 text-right fw-bolder" style={{fontWeight: "700"}}>
                                {Number(invoicePrintDetails.totalAmountGross).toFixed(2)}
                            </td>
                        </tr>
                        <tr style={{ minWidth: "350px", fontSize: "10px" }}>
                            <td colSpan="4" className="text-center py-3">GST @ 18%</td>
                            <td className="py-3 text-right fw-bolder" style={{fontWeight: "700"}}>
                                {Number(invoicePrintDetails.totalDiscounAmount).toFixed(2)}
                            </td>
                        </tr>
                        <tr style={{ minWidth: "350px", fontSize: "10px" }}>
                            <td colSpan="4" className="text-center py-3">Total Costing</td>
                            <td className="py-3 text-right fw-bolder" style={{fontWeight: "700"}}>
                                {Number(invoicePrintDetails.totalAmountNet).toFixed(2)}
                            </td>
                        </tr>

                    </tbody>
                </table>

                <table className="table table-bordered">
                    <thead className="thead-light text-center">
                        <tr style={{ minWidth: "250px", fontSize: "10px" }}>
                            <th className='text-center'>Bank Details</th>
                            <th className='text-center'>Payment Terms</th>
                        </tr>
                    </thead>
                    <tbody style={{ minWidth: "350px", fontSize: "10px" }}>
                        <tr style={{ minWidth: "420px", fontSize: "10px" }}>
                            <td className='py-3' style={{ minWidth: "400px", fontSize: "10px" }}>
                                <div className='d-flex border-bottom'>
                                    <div className='border-right' style={{ minWidth: "220px" }}>Bank Name & Branch:</div>
                                    HDFC Bank Ltd
                                </div>
                                <div className='d-flex border-bottom'>
                                    <div className='border-right' style={{ minWidth: "220px" }}>Beneficiary Name:</div>
                                    APAG Technology & Services Pvt Ltd.
                                </div>
                                <div className='d-flex border-bottom'>
                                    <div className='border-right' style={{ minWidth: "220px" }}>Account No:</div>
                                    1234567890
                                </div>
                                <div className='d-flex border-bottom'>
                                    <div className='border-right' style={{ minWidth: "220px" }}>IFSC Code:</div>
                                    HDFC00011122
                                </div>
                            </td>
                            <td className='py-3' style={{ minWidth: "200px", fontSize: "10px" }}>1) 100% Advance</td>
                        </tr>
                    </tbody>
                </table>

                <div className="text-right" style={{ minWidth: "600px", fontSize: "10px" }}>
                    <p>Director</p>
                    <p>APAG Technology & Services Pvt Ltd</p>
                </div>
            </div>
        </div>
    );
});

export default PrintInvoice;
