import React, { useEffect, useState } from 'react';
import { MdDelete, MdDownload, MdOutlineMoreHoriz } from 'react-icons/md';
import { IoIosPrint } from 'react-icons/io';
import '../TableComponent/tablecomponent.css'
import { toast } from 'sonner';

function PaymentTable({
    currentInvoices,
    expandedRowIndex,
    setExpandedRowIndex,
    onPrint,
    onDownload,
    selectedInvoiceId,
    componentRef,
    handlePrintReady,
    indexOfFirstInvoice,
    indexOfLastInvoice,
    filteredInvoices,
    setCurrentPage,
    totalPages,
    currentPage,
    formatAmount,
    getPaymentStatus = (status) => ({ text: status, color: 'black' }),
    editablePaidAmount = false,
    paidAmounts = {},
    setPaidAmounts,
    paymentList
}) {
    const [totalPrevAmountPaid, setTotalPrevAmountPaid] = useState(0);
    const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
    const [totalPaidAmount, setTotalPaidAmount] = useState(0);
    const [totalBalanceAmount, setTotalBalanceAmount] = useState(0);

    const handleRowClick = (index) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };
    // console.log(currentInvoices)



    useEffect(() => {
        calculateTotals();
    }, [paidAmounts, currentInvoices]);

    const handlePaidAmountChange = (invoiceId, value, balanceAmount) => {
        const numericValue = parseFloat(value);
        const remainingBalance = balanceAmount - (paidAmounts[invoiceId] || 0);

        if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= remainingBalance) {
            setPaidAmounts((prev) => ({
                ...prev,
                [invoiceId]: numericValue,
            }));
        } else if (numericValue > balanceAmount) {
            toast.warning('Paid amount exceeds balance amount');
            setPaidAmounts((prev) => ({
                ...prev,
                [invoiceId]: 0,
            }));
        } else if (value === '') {
            setPaidAmounts((prev) => ({
                ...prev,
                [invoiceId]: '',
            }));
        }

        calculateTotals();
    };

    const calculateTotals = () => {
        let totalPrevAmount = 0;
        let totalInvoice = 0;
        let totalPaid = 0;
        let totalBalance = 0;

        currentInvoices.forEach((invoice) => {
            const paid = paidAmounts[invoice.invoiceId] || 0;
            const balance = invoice.invoiceAmount - paid;

            totalPrevAmount += invoice.previousAmountPaid || 0;
            totalInvoice += invoice.invoiceAmount || 0;
            totalPaid += paid;
            totalBalance += balance;
        });

        setTotalPrevAmountPaid(totalPrevAmount);
        setTotalInvoiceAmount(totalInvoice);
        setTotalPaidAmount(totalPaid);
        setTotalBalanceAmount(totalBalance);
    };



    return (
        <>
            <div className='table-container'>
            <table id="example2" className="table table-hover" >
                <thead>
                    <tr>
                        {editablePaidAmount ? (
                            ''
                        ) : (
                            <th>Cust. Name</th>

                        )}

                        {paymentList ? <th>Payment Number</th> : <th>Invoice Number</th> }

                        {editablePaidAmount ? (
                            <th>Invoice Date</th>
                        ) : (
                            <th>Invoice Date</th>

                        )}

                        {editablePaidAmount ? <th style={{ textAlign: 'right' }}>Prev. Amt. Paid</th> : null }
                        <th style={{ textAlign: 'right' }}>Invoice Amt (₹)</th>
                        {editablePaidAmount ? <th style={{ textAlign: 'center' }}>Paid Amt (₹)</th> : ( <>{paymentList ? null : <th style={{ textAlign: 'center' }}>Payment Status</th>} </>)}
                        <th style={{ textAlign: 'right' }}>Balance Amt (₹)</th>
                        {!editablePaidAmount ? <th>Action</th> : null}
                    </tr>
                </thead>
                <tbody>
                    {currentInvoices.length === 0 ? (
                        <tr>
                            <td colSpan="6" className="text-center">No unpaid invoices found.</td>
                        </tr>
                    ) : (
                        currentInvoices.map((invoice, index) => {
                            const paymentStatus = getPaymentStatus(invoice.paymentStatus);
                            const balanceAmount = invoice.invoiceAmount - (paidAmounts[invoice.invoiceId] || 0);

                            return (
                                <tr key={invoice.invoiceId}>
                                    {editablePaidAmount ? (
                                        ''
                                    ) : (
                                        <td>{invoice.customerName}</td>

                                    )}
                                    {paymentList ? <td>{invoice.paymentNo}</td> : <td>{invoice.invoiceNumber}</td>}

                                    {paymentList ? <td>{new Date(invoice.paymentDate).toLocaleDateString()}</td> : <td>{new Date(invoice.invoiceDate).toLocaleDateString()}</td>}

                                    {editablePaidAmount ? <td style={{ textAlign: 'right' }}>{formatAmount(invoice.previousAmountPaid)}</td> : null}
                                    {editablePaidAmount ? (
                                        <td style={{ textAlign: 'right' }}>{formatAmount(invoice.invoiceAmount)}</td>

                                    ) : (
                                        <>

                                        {paymentList ? <td style={{ textAlign: 'right' }}>{formatAmount(invoice.amount)}</td> : <td style={{ textAlign: 'right' }}>{formatAmount(invoice.totalAmountNet)}</td>}
                                        </>

                                    )}

                                    {editablePaidAmount ? (
                                        <td style={{ textAlign: 'center' }}>
                                             <input
                                                    type="number"
                                                    min="0"
                                                    max={balanceAmount}
                                                    placeholder="0.00"
                                                    value={paidAmounts[invoice.invoiceId] || ''}
                                                    onChange={(e) => handlePaidAmountChange(invoice.invoiceId, e.target.value, balanceAmount)}
                                                    style={{ width: '80px', height: "24px", textAlign: 'right' }}
                                                />
                                        </td>
                                    ) : (
                                        <>
                                        {paymentList ? null : <td style={{ textAlign: 'center', color: paymentStatus.color, fontWeight: 'bold' }}>
                                        {paymentStatus.text}
                                    </td>}

                                    </>
                                    )}

                                    {editablePaidAmount ? (
                                        <td style={{ textAlign: 'right' }}>{formatAmount(invoice.invoiceAmount)}</td>

                                    ) : (
                                        <>

                                        {paymentList ? <td style={{ textAlign: 'right' }}>{formatAmount(invoice.amount)}</td> : <td style={{ textAlign: 'right' }}>{formatAmount(invoice.totalAmountNet)}</td>}
                                        </>

                                    )}


                                    {!editablePaidAmount ? (
                                        <td onClick={() => handleRowClick(index)} className=" actions text-center">
                                            <MdOutlineMoreHoriz size={18} />
                                            {expandedRowIndex === index && (
                                                <div className="row-actions">
                                                    <button onClick={() => onPrint(invoice.invoiceId)}>
                                                        <IoIosPrint /> Print
                                                    </button>
                                                    <button onClick={() => onDownload(invoice.invoiceId)}>
                                                        <MdDownload /> Download
                                                    </button>
                                                    <button onClick={() => console.log('Delete action triggered')}>
                                                        <MdDelete /> Delete
                                                    </button>
                                                </div>
                                            )}
                                        </td>
                                    ) : null}
                                </tr>
                            );
                        })
                    )}
                </tbody>
                <tfoot>
                        <tr>
                            <td colSpan={editablePaidAmount ? 2 : 4} style={{ textAlign: 'right', fontWeight: 'bold' }}>Total:</td>
                            {editablePaidAmount ? <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{formatAmount(totalPrevAmountPaid)}</td> : null}
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{formatAmount(totalInvoiceAmount)}</td>
                            {editablePaidAmount ? <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{formatAmount(totalPaidAmount)}</td> : null}
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{formatAmount(totalBalanceAmount)}</td>
                            {!editablePaidAmount ? <td></td> : null}
                        </tr>
                </tfoot>
            </table>
            </div>

            {/* Pagination */}
            <div className="pagination justify-content-end mt-4">
                <button
                    onClick={() => setCurrentPage(1)}
                    className={`btn ${currentPage === 1 ? 'btn-primary' : 'btn-light'}`}
                    disabled={currentPage === 1}
                >
                    First
                </button>
                <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="btn btn-light"
                    disabled={currentPage === 1}
                >
                    &lt; Previous
                </button>
                {[...Array(totalPages)].map((_, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentPage(index + 1)}
                        className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-light'}`}
                    >
                        {index + 1}
                    </button>
                ))}
                <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className="btn btn-light"
                    disabled={currentPage === totalPages}
                >
                    Next &gt;
                </button>
                <button
                    onClick={() => setCurrentPage(totalPages)}
                    className={`btn ${currentPage === totalPages ? 'btn-primary' : 'btn-light'}`}
                    disabled={currentPage === totalPages}
                >
                    Last
                </button>
            </div>
        </>
    );
}

export default PaymentTable;
