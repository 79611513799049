import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import ProductDropdown from '../../CommonComp/ProductDropdown';
import SearchProductModal from '../../CommonComp/SearchSupplierModal';
import { API_URL } from '../../CommonComp/APIConstants';
import './product.css'

function AddProduct({productDetails, view, isEdit}) {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [categoryList, setCategoryList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [taxSlabList, setTaxSlabList] = useState([]);

    const [productData, setProductsData] = useState([]);
    const [productOption, setProductSelectedOption] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');

    //const [productBomData, setProductBomData] = useState([]);
    const [selectedProductBom, setSelectedProductBom] = useState('');
    const [productBomList, setProductBomList] = useState([]);

    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        // Fetch data from the API when the component mounts
        // fetchSupplierData();
        fetchProductsData();
    }, []); // Empty dependency array ensures that this effect runs only once, similar to componentDidMount


    const fetchProductsData = async () => {
        try {
            const response = await fetch(`${API_URL}/Product/getproductlist`); // Replace with your API endpoint
            const data = await response.json();
            console.log(data);
            setProductsData(data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };


    function handleSelectChangeProduct(e) {
        console.log("targetValue", e.target.value);
        setProductSelectedOption(e.target.value);
        //setSelectedProductId(e.target.value);
    };

    const validateForm = () => {
        let errors = {};

        if (!formData.ProductCode) errors.ProductCode = "Product Code is required";
        if (!formData.ProductName) errors.ProductName = "Product Name is required";
        if (!formData.CategoryId) errors.CategoryId = "Product Category is required";
        if (!formData.RatePerQuantity || isNaN(formData.RatePerQuantity)) errors.RatePerQuantity = "Valid Rate Per Qty. is required";
        if (!formData.SalePrice || isNaN(formData.SalePrice)) errors.SalePrice = "Valid Sale Price is required";

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };


    const handleRowClickProductModal = (row) => {
        // Set the selected row when a row is clicked
        setSelectedProduct(row);
        // setSelectedProductId(row.productId);
        console.log(row);
    };

    const addProductBomToList = () => {
        // const selectedProduct = productData.find(option => option.productId === selectedProductId);
        // const taxAmount = (selectedProduct.ratePerQuantity * (selectedProduct.taxPercentage / 100));

        setProductBomList(productBomList => [...productBomList, {
            Id: '',
            productId: selectedProductBom.productId,
            productName: selectedProductBom.productName,
            ProductCategory: selectedProductBom.productName,
            quantity: 1,
            Unit: 'kg',
            Cmment: ''
        }]);
    }


    const fetchCategoryListData = async () => {
        try {
            const response = await fetch(`${API_URL}/ProductCategory/getproductcategorylist`); // Replace with your API endpoint
            const data = await response.json();
            console.log(data);
            setCategoryList(data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const fetchUnitListData = async () => {
        try {
            const response = await fetch(`${API_URL}/Units/getunitslist`); // Replace with your API endpoint
            const data = await response.json();
            console.log(data);
            setUnitList(data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const fetcTaxSlabListData = async () => {
        try {
            const response = await fetch(`${API_URL}/TaxSlab/gettaxslablist`); // Replace with your API endpoint
            const data = await response.json();
            console.log(data);
            setTaxSlabList(data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };


    useEffect(() => {
        // Fetch data from the API when the component mounts
        fetchCategoryListData();
        fetchUnitListData();
        fetcTaxSlabListData();
    }, []); // Empty dependency array ensures that this effect runs only once, similar to componentDidMount

    const [formData, setFormData] = useState({
        // Initialize your form fields here
        ProductCode: '',
        ProductName: '',
        Barcode: '',
        CategoryId: null,
        QuantityTypeId: null,
        TaxPercentageId: null,
        RatePerQuantity: '',
        SalePrice: '',
        Active: true
        // Add more fields as needed
    });

    useEffect(() => {
        if (productDetails) {
          setFormData({
            ProductCode: productDetails.productCode || '',
            ProductName: productDetails.productName || '',
            Barcode: productDetails.barcode || '',
            CategoryId: productDetails.productCategoryId || null,
            QuantityTypeId: productDetails.quantityTypeId || null,
            TaxPercentageId: productDetails.taxPercentageId || null,
            RatePerQuantity: productDetails.ratePerQuantity || '',
            SalePrice: productDetails.salePrice || '',
            Active: productDetails.active ?? true,
          });

          setSelectedProduct(productDetails);
          setSelectedProductBom(productDetails);
        }
      }, [productDetails]);


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setFormErrors({ ...formErrors, [e.target.name]: '' }); // Clear the error when the user types
    };

    const createProduct = async () => {
        if (!validateForm()) return; // Stop submission if form is invalid

        try {
            setLoading(true);
            const response = await axios.post(`${API_URL}/Product/createnewproduct`, formData);
            console.log("success", response.data)
            navigate("/product_master");
        } catch (error) {
            console.error('Error posting data:', error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (

        <div className="content-wrapper">
            <div className="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h2>Product</h2>
                    </div>
                </div>
                    <div className="card card-default">

                        <div className="card-header">
                            <h3 className="card-title">{isEdit ? 'Edit Product' : 'Product Details'}</h3>

                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus" />
                                </button>
                                <button type="button" className="btn btn-tool" data-card-widget="remove">
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Product Code</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className={`form-control ${formErrors.ProductCode ? 'is-invalid' : ''}`}
                                            name="ProductCode"
                                            value={formData.ProductCode}
                                            onChange={handleChange}
                                            placeholder="Enter Product Code"
                                        />
                                        {formErrors.ProductCode && <div className="invalid-feedback">{formErrors.ProductCode}</div>}
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Product Name</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className={`form-control ${formErrors.ProductName ? 'is-invalid' : ''}`}
                                            name="ProductName"
                                            value={formData.ProductName}
                                            onChange={handleChange}
                                            placeholder="Enter Product Name"
                                        />
                                        {formErrors.ProductName && <div className="invalid-feedback">{formErrors.ProductName}</div>}
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Product Barcode</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {/* <label>Supplier Name</label> */}
                                        <input type="text"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            name="Barcode"
                                            value={formData.Barcode}
                                            onChange={handleChange}
                                            placeholder="Enter Product Barcode" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Product Category</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <select
                                            className={`form-control select2 ${formErrors.CategoryId ? 'is-invalid' : ''}`}
                                            style={{ width: '100%' }}
                                            name="CategoryId"
                                            value={formData.CategoryId}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Product Category</option>
                                            {categoryList.map((categories) => (
                                                <option key={categories.productCategoryId} value={categories.productCategoryId}>
                                                    {categories.categoryName}
                                                </option>
                                            ))}
                                        </select>
                                        {formErrors.CategoryId && <div className="invalid-feedback">{formErrors.CategoryId}</div>}
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Unit Type</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <select
                                            className="form-control select2"
                                            style={{ width: '100%' }}
                                            name="QuantityTypeId"
                                            value={formData.QuantityTypeId}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Product Category</option>
                                            {unitList.map((units) => (
                                                <option key={units.unitId} value={units.unitId}>
                                                    {units.unitName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Rate Per Qty.</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className={`form-control ${formErrors.RatePerQuantity ? 'is-invalid' : ''}`}
                                            name="RatePerQuantity"
                                            value={formData.RatePerQuantity}
                                            onChange={handleChange}
                                            placeholder="Price"
                                        />
                                        {formErrors.RatePerQuantity && <div className="invalid-feedback">{formErrors.RatePerQuantity}</div>}
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Tax(%)</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <select
                                            className="form-control select2"
                                            style={{ width: '100%' }}
                                            name="TaxPercentageId"
                                            value={formData.TaxPercentageId}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Tax</option>
                                            {taxSlabList.map((taxSlabs) => (
                                                <option key={taxSlabs.taxSlabId} value={taxSlabs.taxSlabId}>
                                                    {taxSlabs.taxName}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Sale Price</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className={`form-control ${formErrors.SalePrice ? 'is-invalid' : ''}`}
                                            name="SalePrice"
                                            value={formData.SalePrice}
                                            onChange={handleChange}
                                            placeholder="Enter Sale Price"
                                        />
                                        {formErrors.SalePrice && <div className="invalid-feedback">{formErrors.SalePrice}</div>}
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Attachment</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="exampleInputFile" />
                                                <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">

                                </div>
                                <div className="col-md-4">
                                    <div className="icheck-primary d-inline">
                                        <input type="checkbox" id="checkboxPrimary3" />
                                        <label htmlFor="checkboxPrimary3">
                                            Active
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-10">
                                    {/* <button type="button" class="btn btn-block btn-success">Save</button> */}
                                </div>
                                <div className="col-md-1">
                                    <button type="submit" class="btn btn-block btn-success" disabled={view}
                                    onClick={createProduct}>Save</button>
                                </div>
                                <div className="col-md-1 text-nowrap">
                                    <button type="button" class="btn btn-block btn-danger">Cancel</button>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="card card-default">

                        <div className="card-header">
                            <h3 className="card-title">Product BOM</h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus" />
                                </button>
                                <button type="button" className="btn btn-tool " data-card-widget="remove">
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                        </div>

                        <div className="card-body">
                            <ProductDropdown
                                setSelectedItem={selectedProductBom}
                                data={productData}
                                addProductToList={addProductBomToList} />

                            <div className="row">
                                <div className="col-md-12">
                                    <table id="example2" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                {/* <th>Sr. no.</th> */}
                                                <th style={{ width: '12%' }}>Code</th>
                                                <th style={{ width: '30%' }}>Product Name</th>
                                                <th style={{ width: '30%' }}>Category</th>
                                                <th style={{ width: '8%' }}>Quantity</th>
                                                <th style={{ width: '10%' }}>Unit</th>
                                                <th style={{ width: '10%' }}>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {poListItemsData.map((poItems, index) => (
                                            <tr
                                                key={poItems.productId}
                                            >
                                                <td>{poItems.productName}</td>
                                                <td>
                                                    <input
                                                        type='number'
                                                        value={poItems.quantity}
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => updateQuantityAmount(index, parseInt(e.target.value, 10))} />

                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {poItems.productUnitPrice.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {poItems.totalProductValue.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>

                                                <td style={{ textAlign: 'right' }}>
                                                    <input
                                                        type='number'
                                                        value={poItems.discountPercentage}
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => updateDiscount(index, parseInt(e.target.value, 10))} />
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {poItems.discountAmount.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>

                                                <td style={{ textAlign: 'right' }}>
                                                    {poItems.totalPurchaseAmount.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>

                                                <td style={{ textAlign: 'right' }}>
                                                    {poItems.taxPercentage}</td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {poItems.taxAmount.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>

                                                <td style={{ textAlign: 'right' }}>
                                                    {poItems.totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>


                                                <td>
                                                    <button type="button" onClick={() => removeProduct(poItems.productId)} className="btn btn-block btn-danger">Delete</button>
                                                </td>
                                            </tr>
                                        ))} */}

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                    </div>

                {/* /.card */}
            </div>

            <SearchProductModal
                data={productData}
                rowClick={(products) => handleRowClickProductModal(products)}
                selectedRow={setSelectedProduct}
            />
        </div>
    );
}

export default AddProduct;