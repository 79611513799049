import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import PaymentTable from "../../CommonComp/invoiceandpaymenttable/PaymentTable";
import { toast } from "sonner";
import { API_URL } from "../../CommonComp/APIConstants";
import './payment.css'
import { IoIosClose } from "react-icons/io";

function AddPayment() {
    const [customers, setCustomers] = useState([]);
    const [unpaidInvoices, setUnpaidInvoices] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [selectedPaymentType, setSelectedPaymentType] = useState("");
    const [chequeNumber, setChequeNumber] = useState("");
    const [onlineType, setOnlineType] = useState("");
    const [chequeDate, setChequeDate] = useState("");
    const [chequeImage, setChequeImage] = useState(null);
    const [totalAmount, setTotalAmount] = useState("");
    const [paidAmounts, setPaidAmounts] = useState({});
    const [loading, setLoading] = useState(false);
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    // Validation states
    const [errors, setErrors] = useState({
        customer: false,
        amount: false,
        paymentType: false,
    });

    const indexOfFirstInvoice = (currentPage - 1) * 15;
    const indexOfLastInvoice = currentPage * 15;
    const currentInvoices = unpaidInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);


    const handlePrint = (invoiceId) => {
        console.log("Print invoice:", invoiceId);
    };

    const handleDownload = (invoiceId) => {
        console.log("Download invoice:", invoiceId);
    };

    const getPaymentStatus = (status) => {
        switch (status) {
            case "paid":
                return { text: "Paid", color: "green" };
            case "pending":
                return { text: "Pending", color: "red" };
            default:
                return { text: "Unknown", color: "gray" };
        }
    };



    const formatAmount = (amount) => {
        return amount ? amount.toFixed(2) : '0.00';
    };

    useEffect(() => {
        axios.get(`${API_URL}/Customer/getcustomerlist`)
            .then((response) => setCustomers(response.data))
            .catch((error) => console.error("Error fetching customers!", error));
    }, []);

    const handleCustomerChange = (e) => {
        const customerId = e.target.value;
        setSelectedCustomer(customerId);
        setErrors((prev) => ({ ...prev, customer: !customerId }));

        if (customerId) {
            setLoading(true);
            axios.get(`${API_URL}/Invoice/Invoice/GetUnpaidInvoiceList?id=${customerId}`)
                .then((response) => {
                    setUnpaidInvoices(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching invoices!", error);
                    setLoading(false);
                });
        } else {
            setUnpaidInvoices([]);
        }
    };

    const handlePaidAmountChange = (invoiceId, amount) => {
        setPaidAmounts((prevState) => ({
            ...prevState,
            [invoiceId]: amount,
        }));
    };

    const handleSavePayments = () => {
        let valid = true;
        const newErrors = { customer: false, amount: false, paymentType: false };

        // Validate customer
        if (!selectedCustomer) {
            newErrors.customer = true;
            valid = false;
        }

        if (!totalAmount || isNaN(totalAmount) || parseFloat(totalAmount) <= 0) {
            newErrors.amount = true;
            valid = false;
        }

        if (!selectedPaymentType) {
            newErrors.paymentType = true;
            valid = false;
        }

        setErrors(newErrors);

        if (!valid) {
            toast.error("Please fill in all required fields.");
            return;
        }

        const paymentsToSave = Object.entries(paidAmounts).map(([invoiceId, paidAmount]) => ({
            invoiceId,
            amountPosted: parseFloat(paidAmount),
            paymentPostingDate: new Date().toISOString(),
        }));

        const requestPayload = {
            payment: {
                customerId: selectedCustomer,
                userId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                paymentDate: new Date().toISOString(),
                paymentMode: selectedPaymentType === "Cheque" ? 1 : selectedPaymentType === "Online" ? 2 : 0,
                paymentType: selectedPaymentType === "Cheque" ? 1 : selectedPaymentType === "Online" ? 2 : 0,
                amount: parseFloat(totalAmount),
                checkNo: chequeNumber || "",
                checkDate: chequeDate || null,
                checkImage: chequeImage || "",
                note: "",
            },
            paymentDetails: paymentsToSave,
        };

        axios.post(`${API_URL}/Payment/AddPayment`, requestPayload)
            .then((response) => toast("Payments saved successfully!"))
            .catch((error) => {
                console.error("Error saving payments!", error);
                toast.error("Error saving the payments.");
            });
    };

    const handleAmountChange = (e) => {
        const inputValue = e.target.value;

        if (/^\d*\.?\d{0,2}$/.test(inputValue) || inputValue === "") {
            setTotalAmount(inputValue);
            setErrors((prev) => ({ ...prev, amount: false }));
        }
    };

    const handleBlur = () => {
        if (totalAmount !== "") {
            setTotalAmount(parseFloat(totalAmount).toFixed(2));
        }
    };

    const handleChequeImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setChequeImage(URL.createObjectURL(e.target.files[0]));
        }
    };

    const handleRemoveImage = () => setChequeImage(null);

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h2>Add Payment</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row mb-2">
                                    <div className="col-md-10 d-flex align-items-center">
                                        <div className="mr-2">
                                            <label htmlFor="customer">Customer</label>
                                            <select
                                                id="customer"
                                                value={selectedCustomer}
                                                onChange={handleCustomerChange}
                                                className={`addpay-dropdown form-control w-auto ${errors.customer ? 'border-danger' : ''}`}
                                            >
                                                <option value="">Select Customer</option>
                                                {customers.map((customer) => (
                                                    <option key={customer.customerId} value={customer.customerId}>
                                                        {customer.customerName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="mr-1">
                                            <label htmlFor="amount">Amount</label>
                                            <input
                                                type="text"
                                                id="amount"
                                                className={`date-input payment-input ${errors.amount ? 'border-danger' : ''}`}
                                                placeholder="Enter amount"
                                                value={totalAmount}
                                                onChange={handleAmountChange}
                                                onBlur={handleBlur}
                                            />
                                        </div>

                                        <div className="mr-1">
                                            <label htmlFor="paymentType">Payment Type</label>
                                            <select
                                                id="paymentType"
                                                value={selectedPaymentType}
                                                onChange={(e) => {
                                                    setSelectedPaymentType(e.target.value);
                                                    setErrors((prev) => ({ ...prev, paymentType: !e.target.value }));
                                                }}
                                                className={`range-dropdown form-control w-auto ${errors.paymentType ? 'border-danger' : ''}`}
                                            >
                                                <option value="">Payment Type</option>
                                                <option value="Cash">Cash</option>
                                                <option value="Cheque">Cheque</option>
                                                <option value="Online">Online</option>
                                            </select>
                                        </div>
                                        <div   div className="ml-1 w-25">
                                            <label htmlFor="note">Note</label>
                                            <input type="text" id="note" className="addpay-dropdown  w-50 payment-input" />
                                        </div>
                                    </div>
                                </div>

                                {selectedPaymentType === "Cheque" && (
                                    <div className="row mb-2">
                                        <div className="col-md-3 d-flex flex-column justify-content-center">
                                            <label htmlFor="chequeNumber">Cheque Number</label>
                                            <input
                                                type="text"
                                                id="chequeNumber"
                                                value={chequeNumber}
                                                onChange={(e) => setChequeNumber(e.target.value)}
                                                className="cheque-inputs range-dropdown"
                                                placeholder="Enter Cheque Number"
                                            />
                                        </div>
                                        <div className="col-md-3 d-flex flex-column justify-content-center">
                                            <label htmlFor="chequeDate">Cheque Date</label>
                                            <input
                                                type="date"
                                                id="chequeDate"
                                                value={chequeDate}
                                                onChange={(e) => setChequeDate(e.target.value)}
                                                className="cheque-inputs range-dropdown"
                                            />
                                        </div>
                                        <div className="col-md-3 d-flex flex-column justify-content-center">
                                            <label htmlFor="chequeImage">Cheque Image</label>
                                            <label htmlFor="chequeImage" className="custom-file-upload">

                                                {chequeImage ? (
                                                    <>
                                                        <img src={chequeImage} alt="Cheque Preview" className="cheque-preview" />
                                                        <IoIosClose
                                                            size={20}
                                                            color="red"
                                                            className="remove-icon ml-2"

                                                            onClick={handleRemoveImage}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* <MdCloudUpload size={24} /> */}
                                                        <span>Upload Cheque Image</span>
                                                    </>
                                                )}
                                            </label>
                                            <input
                                                type="file"
                                                id="chequeImage"
                                                onChange={handleChequeImageChange}
                                                className="cheque-inputs"
                                                accept="image/*"
                                                hidden
                                            />
                                        </div>
                                    </div>
                                )}

                            {selectedPaymentType === "Online" && (
                                    <div className="row mb-2">
                                        <div className="col-md-3 d-flex flex-column justify-content-center">
                                            <label htmlFor="onlineType">Type</label>
                                            <input
                                                type="text"
                                                id="onlineType"
                                                value={onlineType}
                                                onChange={(e) => setOnlineType(e.target.value)}
                                                className="cheque-inputs range-dropdown"
                                                placeholder="Enter Type"
                                            />
                                        </div>

                            </div>
                                    )}



                                    <PaymentTable
                                        currentInvoices={currentInvoices}
                                        expandedRowIndex={expandedRowIndex}
                                        setExpandedRowIndex={setExpandedRowIndex}
                                        onPrint={handlePrint}
                                        onDownload={handleDownload}
                                        selectedInvoiceId={null}
                                        handlePrintReady={() => {}}
                                        indexOfFirstInvoice={indexOfFirstInvoice}
                                        indexOfLastInvoice={indexOfLastInvoice}
                                        filteredInvoices={unpaidInvoices}
                                        setCurrentPage={setCurrentPage}
                                        totalPages={Math.ceil(unpaidInvoices.length / 10)}
                                        currentPage={currentPage}
                                        formatAmount={formatAmount}
                                        getPaymentStatus={getPaymentStatus}
                                        editablePaidAmount={true}
                                        paidAmounts={paidAmounts}
                                        setPaidAmounts={setPaidAmounts}
                                    />

                                <div className="row">
                                    <div className="col-md-12 text-right">
                                        <button
                                            onClick={handleSavePayments}
                                            className="btn btn-success"
                                            disabled={loading}
                                        >
                                            {loading ? <Spinner animation="border" size="sm" /> : "Save Payments"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddPayment;
