import React from "react";
import { NavLink } from 'react-router-dom';

function SideNav() {
  return (
    <div>
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <a href="index3.html" className="brand-link">
          <img
            src="dist/img/AdminLTELogo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light">APAG ERP</span>
        </a>

        {/* Sidebar */}
        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src="dist/img/user2-160x160.jpg"
                className="img-circle elevation-2"
                alt="User Img"
              />
            </div>
            <div className="info">
              <a href="#" className="d-block">
                Priyanka Gosavi
              </a>
            </div>
          </div>

          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Dashboard */}
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/dashboard"
                  activeClassName="active"
                >
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>Dashboard</p>
                </NavLink>
              </li>

              {/* Users */}
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/users_master"
                  activeClassName="active"
                >
                  <i className="nav-icon fa fa-user-circle" />
                  <p>Users</p>
                </NavLink>
              </li>

              {/* Supplier */}
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/supplier_master"
                  activeClassName="active"
                >
                  <i className="nav-icon fa fa-id-card" />
                  <p>Supplier</p>
                </NavLink>
              </li>

              {/* Customer */}
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/customer_master"
                  activeClassName="active"
                >
                  <i className="nav-icon fa fa-users" />
                  <p>Customer</p>
                </NavLink>
              </li>

              {/* Product */}
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/product_master"
                  activeClassName="active"
                >
                  <i className="nav-icon fa fa-cubes" />
                  <p>Product</p>
                </NavLink>
              </li>

              {/* Work Order */}
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/work_order"
                  activeClassName="active"
                >
                  <i className="nav-icon fa fa-cubes" />
                  <p>Work Order</p>
                </NavLink>
              </li>

              {/* Purchase Order */}
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/purchase_order_master"
                  activeClassName="active"
                >
                  <i className="nav-icon fa fa-shopping-basket" />
                  <p>Purchase Order</p>
                </NavLink>
              </li>

              {/* Invoices */}
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/invoice_master"
                  activeClassName="active"
                >
                  <i className="nav-icon fa fa-shopping-basket" />
                  <p>Invoices</p>
                </NavLink>
              </li>

              {/* CRM Customer */}
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/crm_customer_master"
                  activeClassName="active"
                >
                  <i className="nav-icon fa fa-cog"></i>
                  <p>Crm Customer</p>
                </NavLink>
              </li>

              {/* Payments */}
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/payments"
                  activeClassName="active"
                >
                  <i className="nav-icon fa fa-credit-card" />
                  <p>Payments</p>
                </NavLink>
              </li>

              {/* Reports */}
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/reports"
                  activeClassName="active"
                >
                  <i className="nav-icon fas fa-chart-pie" />
                  <p>Reports</p>
                </NavLink>
              </li>

              {/* Settings */}
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/settings"
                  activeClassName="active"
                >
                  <i className="nav-icon fa fa-cog"></i>
                  <p>Settings</p>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
}

export default SideNav;
