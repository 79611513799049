import React, { useState, useEffect, useRef } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FcEmptyTrash } from "react-icons/fc";
import { API_URL } from "../../CommonComp/APIConstants";
import FormTable from "../../CommonComp/TableComponent/Invoice&purchaseTable/FormTable";
import { toast } from "sonner";
import axios from "axios";
import './invoicespage.css'
import PrintInvoice from "./PrintInvoice";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import { html2pdf } from "html2pdf.js";
import SuccessCheckIcon from './success-check.svg'
// import html2canvas from "html2canvas";
import * as html2canvas from 'html2canvas';
import jsPDF from "jspdf";

function CreateInvoice() {

    const navigate = useNavigate()
    const printRef = useRef();
    const clickTimeoutRef = useRef(null);
    const modalRef = useRef(null);

    const [productOption, setProductSelectedOption] = useState([]);
    const [customersData, setCustomersData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [customerSelectedRow, setCustomerSelectedRow] = useState(null);
    const [customerCodeInput, setCustomerCodeInput] = useState('');
    const [customerGstInput, setCustomerGstInput] = useState('');
    //const [customerCodeInput, setCustomerCodeInput] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedCustomerOption, setSelectedCustomerOption] = useState('');
    //const [customerOption, setSelectedCustomerOption] = useState([]);
    const [discTotalAmount, setDiscTotalAmount] = useState([0.0]);
    const [subTotalAmount, setSubTotalAmount] = useState([0.0]);
    const [grandTotalAmount, setGrandTotalAmount] = useState([0.0]);
    const [selectedProductId, setSelectedProductId] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [invProdListItemsData, setInvProdListItemsData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQuerySup, setSearchQuerySup] = useState('');
    const [customerCode, setCustomerCode] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerNameInput, setCustomerNameInput] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [gstNo, setGstNo] = useState('');
    const [panNo, setPanNo] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sucessModalOpen, setSuccessModalOpen] = useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [invoiceId, setInvoiceId] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(12);



    // console.log(invoiceNumber)


    useEffect(() => {
        fetchCustomersData();
        fetchProductsData();
    }, []);

    const filteredCustomers = customersData.filter(customer =>
        customer.customerName.toLowerCase().includes(searchQuerySup.toLowerCase())
    );

    const totalPages = Math.ceil(filteredCustomers.length / itemsPerPage);

    const handleClick = (page) => {
        setCurrentPage(page);
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const handlePreviousPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    const handleLastPage = () => {
        setCurrentPage(totalPages);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentCustomers = filteredCustomers.slice(indexOfFirstItem, indexOfLastItem);

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    const handleDownloadPDF = () => {
        // const printRef = useRef();
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: [650, 850] // A4 size
        });

        const htmlContent = printRef.current.innerHTML;

        pdf.html(htmlContent, {
          paddings: {
            top: 20,
            left: 20,
            bottom: 20,
            right: 20
          },
          scale: 1,
          callback: function () {
            pdf.save('invoice.pdf');
          }
        });
      };










    const fetchCustomersData = async () => {
        try {
            const response = await fetch(`${API_URL}/Customer/getcustomerlist`);
            const data = await response.json();
            setCustomersData(data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const fetchProductsData = async () => {
        try {
            const response = await fetch(`${API_URL}/Product/getproductlist`);
            const data = await response.json();
            setProductsData(data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const ProductModelRowClick = (row) => {
        setSelectedRow(row);
        setSelectedProductId(row.productId);
        console.log(row);
    };

    const handleRowClick = (row) => {
        clickTimeoutRef.current = setTimeout(() => {
            CustomerModelRowClick(row);
        }, 200);
    };

    const handleRowDoubleClick = (row) => {
        clearTimeout(clickTimeoutRef.current);
        CustomerModelRowClick(row);
        closeModal();
    };

    const closeModal = () => {
        const modalElement = modalRef.current;

        modalElement.classList.remove('show');
        modalElement.setAttribute('aria-hidden', 'true');
        modalElement.style.display = 'none';

        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
            backdrop.parentNode.removeChild(backdrop);
        }
    };

    const CustomerModelRowClick = (row) => {
        setSelectedRow(row);
        setSelectedCustomersData(row.customerId);
    };

    function setSelectedCustomersData(customerId) {

        const selectedCustomer = customersData.find(option => option.customerId === customerId);
        setCustomerName(selectedCustomer.customerName);
        setSelectedCustomerId(selectedCustomer.customerId);
        setCustomerCodeInput(selectedCustomer.customerCode);
        setCustomerGstInput(selectedCustomer.customerGSTNumber);
        setCustomerName(selectedCustomer.customerName);
    };

    function handleSelectCustomerName(e) {
        setSelectedCustomersData(e.target.value);
    };

    function handleSelectChangeProduct(e) {
        console.log("targetValue", e.target.value);
        setSelectedProductId(e.target.value);
    };

    const addProductToList = () => {
        const selectedProduct = productsData.find(option => option.productId === selectedProductId);
        const taxAmount = (selectedProduct.ratePerQuantity * (selectedProduct.taxPercentage / 100));

        setInvProdListItemsData(prevItems => {
            const existingProductIndex = prevItems.findIndex(item => item.productId === selectedProductId);

            if (existingProductIndex !== -1) {

                toast.warning("As Product is already present, Quantity will be updated")

                const updatedItems = [...prevItems];
                const existingProduct = updatedItems[existingProductIndex];
                const newQuantity = existingProduct.quantity + 1;
                const newTotalProductValue = parseFloat(selectedProduct.ratePerQuantity * newQuantity);
                const newTaxAmount = parseFloat((selectedProduct.ratePerQuantity * (selectedProduct.taxPercentage / 100)) * newQuantity);
                const newTotalAmount = newTotalProductValue + newTaxAmount;

                updatedItems[existingProductIndex] = {
                    ...existingProduct,
                    quantity: newQuantity,
                    totalProductValue: newTotalProductValue,
                    taxAmount: newTaxAmount,
                    totalAmount: newTotalAmount,
                    totalPurchaseAmount: newTotalAmount
                };

                setSubTotalAmount(prevSubTotal => parseFloat(prevSubTotal) + parseFloat(selectedProduct.ratePerQuantity));
                setDiscTotalAmount(prevDiscTotal => parseFloat(prevDiscTotal) + parseFloat(selectedProduct.discountAmount) || 0);
                setGrandTotalAmount(prevGrandTotal => parseFloat(prevGrandTotal) + (parseFloat(selectedProduct.ratePerQuantity) + taxAmount) || 0);

                return updatedItems;
            } else {
                setSubTotalAmount(prevSubTotal => parseFloat(prevSubTotal) + (parseFloat(selectedProduct.ratePerQuantity) + taxAmount));
                setDiscTotalAmount(prevDiscTotal => parseFloat(prevDiscTotal) + parseFloat(selectedProduct.discountAmount) || 0);
                setGrandTotalAmount(prevGrandTotal => parseFloat(prevGrandTotal) + (parseFloat(selectedProduct.ratePerQuantity) + taxAmount) || 0);

                return [
                    ...prevItems,
                    {
                        supplierCode: '',
                        productId: selectedProduct.productId,
                        productName: selectedProduct.productName,
                        productUnitPrice: parseFloat(selectedProduct.ratePerQuantity),
                        quantity: 1,
                        totalProductValue: parseFloat((selectedProduct.ratePerQuantity * 1)),
                        discountPercentage: 0,
                        discountAmount: 0,
                        totalPurchaseAmount: parseFloat((selectedProduct.ratePerQuantity * 1)),
                        taxPercentage: parseFloat(selectedProduct.taxPercentage),
                        taxAmount: parseFloat(taxAmount),
                        totalAmount: parseFloat((selectedProduct.ratePerQuantity + taxAmount))
                    }
                ];
            }
        });
    };



    const updateQuantityAmount = (index, quantity) => {
        const updatedProducts = [...invProdListItemsData];
        updatedProducts[index].quantity = quantity;

        let totalProductValue = updatedProducts[index].quantity * updatedProducts[index].productUnitPrice;
        if (isNaN(totalProductValue)) {
            totalProductValue = 0;
        }

        let totalPurchaseAmount = totalProductValue - updatedProducts[index].discountAmount;
        if (isNaN(totalPurchaseAmount)) {
            totalPurchaseAmount = 0;
        }

        let totalTaxAmount = totalPurchaseAmount * (updatedProducts[index].taxPercentage / 100);
        if (isNaN(totalTaxAmount)) {
            totalTaxAmount = 0;
        }

        let totalAmount = totalPurchaseAmount;
        if (updatedProducts[index].taxPercentage > 0) {
            totalAmount = totalPurchaseAmount + (totalTaxAmount);
            if (isNaN(totalAmount)) {
                totalAmount = 0;
            }
        }

        updatedProducts[index].totalProductValue = totalProductValue;
        updatedProducts[index].totalPurchaseAmount = totalPurchaseAmount;
        updatedProducts[index].taxAmount = totalTaxAmount
        updatedProducts[index].totalPurchaseAmount = totalPurchaseAmount;
        updatedProducts[index].totalAmount = totalAmount;
        setInvProdListItemsData(updatedProducts);
        updateTotalAmount();
    }


 const invoicePrintDetails = {
                invoiceDate: new Date().toISOString(),
                dueDate: new Date().toISOString(),
                totalAmountGross: subTotalAmount,
                totalDiscounAmount: discTotalAmount,
                totalAmountNet: grandTotalAmount,
                totalItems: invProdListItemsData.length,
                invoiceStatus: 0,
                paymentStatus: 0,
                customerId: selectedCustomerId,
                customerName: customerName,
                customerGST: customerGstInput,
                customerAddress: '',
 }

 const invoiceDetails = invProdListItemsData.map((item, index) => ({
                productId: item.productId,
                productName: item.productName,
                productCode: item.productCode,
                unitPrice: item.productUnitPrice,
                quantity: item.quantity,
                discountPercentage: item.discountPercentage,
                discounAmount: item.discountAmount,
                totalAmount: item.totalAmount,
                gst1Percentage: item.taxPercentage,
                gst1Amount: item.taxAmount,
                gst2Percentage: 0,
                gst2Amount: 0,
                sequenceNumber: index + 1
            }));

    const handleCreateInvoice = async () => {
        try {




            const payload = {
                invoiceDate: new Date().toISOString(),
                dueDate: new Date().toISOString(),
                totalAmountGross: subTotalAmount,
                totalDiscounAmount: discTotalAmount,
                totalAmountNet: grandTotalAmount,
                totalItems: invProdListItemsData.length,
                invoiceStatus: 0,
                paymentStatus: 0,
                customerId: selectedCustomerId,
                customerName: customerName,
                customerGST: customerGstInput,
                customerAddress: '',
                invoiceDetails: invoiceDetails
            };

            const response = await axios.post(`${API_URL}/Invoice/CreateNewInvoice`, payload);
            console.log('Invoice created successfully:', response.data);
            setInvoiceNumber(response.data.data.invoiceNumber);
            setInvoiceId(response.data.data.invoiceId)
            toast.success('Invoice created successfully');

            // Show success modal
            setSuccessModalOpen(true);

        } catch (error) {
            console.error('Error creating invoice:', error);
            toast.error('Error creating invoice:', error);
        }
    };



    const updateDiscount = (index, discount) => {
        const updatedProducts = [...invProdListItemsData];
        if (isNaN(discount)) {
            discount = 0;
        }

        updatedProducts[index].discountPercentage = discount;

        let discountAmount = updatedProducts[index].totalProductValue * (discount / 100);
        if (isNaN(discountAmount)) {
            discountAmount = 0;
        }

        updatedProducts[index].discountAmount = discountAmount;

        updatedProducts[index].totalPurchaseAmount = updatedProducts[index].totalProductValue - discountAmount;

        let totalTaxAmount = updatedProducts[index].totalPurchaseAmount * (updatedProducts[index].taxPercentage / 100);
        if (isNaN(totalTaxAmount)) {
            totalTaxAmount = 0;
        }

        let totalAmount = updatedProducts[index].totalPurchaseAmount + totalTaxAmount;
        if (isNaN(totalAmount)) {
            totalAmount = 0;
        }

        updatedProducts[index].taxAmount = totalTaxAmount;
        updatedProducts[index].totalAmount = totalAmount;

        setInvProdListItemsData(updatedProducts);
        updateTotalAmount();
    };


    const updateTotalAmount = () => {
        const subtotal = invProdListItemsData.reduce((total, item) => total + item.totalProductValue, 0);
        setSubTotalAmount(parseFloat(subtotal));

        const discountTotal = invProdListItemsData.reduce((total, item) => total + item.discountAmount, 0);
        setDiscTotalAmount(parseFloat(discountTotal));

        const grandTotal = invProdListItemsData.reduce((total, item) => total + item.totalAmount, 0);
        if (isNaN(grandTotal)) {
            setGrandTotalAmount(0);
        } else {
            setGrandTotalAmount(grandTotal);
        }
    };
    const removeProduct = (id) => {
        const updatedData = invProdListItemsData.filter((item) => item.productId !== id);
        setInvProdListItemsData(updatedData);
        updateTotalAmount();
    };


    const handleCreateCustomer = async () => {
        if (customerCode.trim() === '' || customerName.trim() === '' || mobileNo.trim() === '') {
            alert('Customer Name and Mobile No. are mandatory fields.');
            return;
        }

        const newCustomer = {
            CustomerCode: customerCode,
            CustomerName: customerNameInput,
            CustomerEmail: email,
            CustomerPhonenumber: mobileNo,
            CustomerGSTNumber: gstNo,
            CustomerPANNumber: panNo,
        };

        try {
            const response = await axios.post(`${API_URL}/Customer/createnewcustomer`, newCustomer);
            console.log('API Response:', response.data);

            const createdCustomer = response.data;
            console.log('this is created customer',createdCustomer.data.customerId)

            setCustomersData([...customersData, createdCustomer.data]);

            setSelectedCustomersData(createdCustomer.data.customerId);


            toast.success('Customer created successfully!');

            setIsModalOpen(false);
            // fetchCustomersData();

            setCustomerName('');
            setMobileNo('');
            setEmail('');
            setPhoneNo('');
            setGstNo('');
            setPanNo('');

        } catch (error) {
            console.error('Error posting data:', error);
            toast.error('Failed to create customer. Please try again.');
        }
    };

    const handlePrintInvoice = () => {
        const printContents = document.getElementById("invoiceTable").innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    };



    const openSucessModal = () => {
        setSuccessModalOpen(prev => !prev);
        navigate('/invoice_master')
    };
    const openModal = () => {
        setIsModalOpen(true);
    };



    const filteredCustomer = customersData.filter(product =>
        product.customerName.toLowerCase().includes(searchQuerySup.toLowerCase()) ||
        product.customerGSTNumber.toLowerCase().includes(searchQuerySup.toLowerCase())
    );

    const filteredProducts = productsData.filter(product =>
        product.productName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.productCode.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="content-wrapper">
            <div className="container-fluid">

                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h2>Create Sale Invoice</h2>
                    </div>
                </div>

                <div className="card card-default">
                    <div className="card-body">

                        <div className="row">
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Customer Code</label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <div className="input-group-append border border-[#252525] rounded">
                                        <input
                                            type="text"
                                            className="form-control border-0"
                                            id="customerCodeTxt"
                                            placeholder="Customer Code"
                                            value={customerCodeInput}
                                            onChange={setCustomerCodeInput} />
                                        <button className="btn btn-sidebar" onClick={openModal}>
                                            <p className="fw-bold mb-0" style={{ fontSize: '28px', lineHeight: '0', marginTop: "-3px" }}>+</p>
                                        </button>
                                        {/* <button className="btn btn-sidebar" onClick={openSucessModal}>
                                            <p className="fw-bold mb-0" style={{ fontSize: '28px', lineHeight: '0', marginTop: "-3px" }}>+</p>
                                        </button> */}

                                        <button className="btn btn-sidebar" data-toggle="modal" data-target="#supplierModal-lg">
                                            <i className="fas fa-search fa-fw" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Invoice Date</label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <DatePicker
                                        className='w-100'
                                        style={{width: "100%"}}
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Select a date"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Customer Name</label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <select
                                        className="form-control select2"
                                        style={{ width: '100%' }}
                                        value={selectedCustomerId}
                                        onChange={handleSelectCustomerName}
                                    >
                                        <option value="">Select Customer</option>
                                        {customersData.map((option) => (
                                            <option key={option.customerId} value={option.customerId}>
                                                {option.customerName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Supplier GST No.</label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="supplierGstTxt"
                                        placeholder="Enter GST Number"
                                        value={customerGstInput}
                                        onChange={setCustomerGstInput} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Select Product</label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <div className="input-group-append border border-[#252525] rounded">
                                        <select
                                            className="form-control select2 border-0"
                                            style={{ width: '100%' }}
                                            value={selectedProductId}
                                            onChange={handleSelectChangeProduct}
                                        >
                                            <option value="">Select Product</option>
                                            {productsData.map((product) => (
                                                <option key={product.productId} value={product.productId}>
                                                    {product.productName}
                                                </option>
                                            ))}
                                        </select>
                                        <button className="btn btn-sidebar" data-toggle="modal" data-target="#productModal-lg">
                                            <i className="fas fa-search fa-fw" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1">
                                <button type="button" class="btn btn-block btn-success" onClick={addProductToList}>Add</button>
                            </div>
                        </div>

                        <FormTable
                            invProdListItemsData={invProdListItemsData}
                            subTotalAmount={subTotalAmount}
                            discTotalAmount={discTotalAmount}
                            grandTotalAmount={grandTotalAmount}
                            updateQuantityAmount={updateQuantityAmount}
                            updateDiscount={updateDiscount}
                            removeProduct={removeProduct}
                            createSaleInvoice={handleCreateInvoice}
                            //   additionalActions={() => (
                            //     // You can pass additional actions or elements here
                            //     <div>Additional Action Button or Elements</div>
                            //   )}
                            />

                        </div>
                </div>
        </div>
            {/* Supplier Model Popup*/}
            <div className="modal fade" id="supplierModal-lg" ref={modalRef}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Select Supplier</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input
                            type="text"
                            className="form-control mb-3"
                            placeholder="Search..."
                            value={searchQuerySup}
                            onChange={(e) => setSearchQuerySup(e.target.value)}
                        />
                        <table id="example2" className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>GST</th>
                                    <th>Phonenumber</th>
                                </tr>
                            </thead>
                            <tbody>
                            {currentCustomers.map(options => (
                                    <tr
                                        key={options.customerId}
                                        onClick={() => {
                                            handleRowClick(options);
                                            setSelectedRow(options);
                                        }}
                                        onDoubleClick={() => handleRowDoubleClick(options)}
                                        style={{ backgroundColor: selectedRow === options ? 'lightblue' : 'white' }}
                                    >
                                        <td style={{userSelect: "none"}}>{options.customerName}</td>
                                        <td style={{userSelect: "none"}}>{options.customerGSTNumber}</td>
                                        <td style={{userSelect: "none"}}>{options.customerPhonenumber}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        <div>
                            <button
                                className="btn btn-primary"
                                onClick={handleFirstPage}
                                disabled={currentPage === 1}>
                                First
                            </button>
                            <button
                                className="btn btn-light"
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}>
                                Previous
                            </button>
                            <span className="mx-2">Page {currentPage} of {totalPages}</span>
                            <button
                                className="btn btn-light"
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}>
                                Next
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={handleLastPage}
                                disabled={currentPage === totalPages}>
                                Last
                            </button>
                        </div>

                        <button type="button" className="btn btn-primary" data-dismiss="modal">Select</button>
                    </div>
                </div>
            </div>
        </div>

            {/* Product Model Popup*/}
            <div className="modal fade" id="productModal-lg">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Select Product</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input
                            type="text"
                            className="form-control mb-3"
                            placeholder="Search by Product Name or Code"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />

                        <table id="example2" className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Product Code</th>
                                    <th>Product Name</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProducts.map(product => (
                                    <tr
                                        key={product.productId}
                                        onClick={() => ProductModelRowClick(product)}
                                        style={{ backgroundColor: selectedRow === product ? 'lightblue' : 'white' }}
                                    >
                                        <td>{product.productCode}</td>
                                        <td>{product.productName}</td>
                                        <td>{product.ratePerQuantity}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal">Select</button>
                    </div>
                </div>
            </div>
        </div>

        {/* Create customer modal */}

        {isModalOpen && (
        <div className="modal fade show d-block" id="customerModal-lg" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Create Customer</h4>
                        <button type="button" className="close" onClick={() => setIsModalOpen(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="customerName">Customer Code <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="customerCode"
                                    value={customerCode}
                                    onChange={(e) => setCustomerCode(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="customerName">Customer Name <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="customerName"
                                    value={customerNameInput}
                                    onChange={(e) => setCustomerNameInput(e.target.value)}
                                    required
                                />
                            </div>

                            {/* Mobile Number */}
                            <div className="form-group col-md-6">
                                <label htmlFor="mobileNo">Mobile No. <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="mobileNo"
                                    value={mobileNo}
                                    onChange={(e) => setMobileNo(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            {/* Email (Optional) */}
                            <div className="form-group col-md-6">
                                <label htmlFor="email">Email (Optional)</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            {/* Phone Number (Optional) */}
                            <div className="form-group col-md-6">
                                <label htmlFor="phoneNo">Phone No. (Optional)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="phoneNo"
                                    value={phoneNo}
                                    onChange={(e) => setPhoneNo(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            {/* GST Number (Optional) */}
                            <div className="form-group col-md-6">
                                <label htmlFor="gstNo">GST No. (Optional)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="gstNo"
                                    value={gstNo}
                                    onChange={(e) => setGstNo(e.target.value)}
                                />
                            </div>

                            {/* PAN Number (Optional) */}
                            <div className="form-group col-md-6">
                                <label htmlFor="panNo">PAN No. (Optional)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="panNo"
                                    value={panNo}
                                    onChange={(e) => setPanNo(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-default" onClick={() => setIsModalOpen(false)}>Close</button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleCreateCustomer}
                        >
                            Create Customer
                        </button>
                    </div>
                </div>
            </div>
        </div>
        )}

    {sucessModalOpen && (

        <div className="invoice-success modal fade show d-block overflow-y-scroll mx-auto" style={{ width: "auto"}} id="successModal" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered " style={{maxWidth: "700px"}} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="successModalLabel">Invoice Created Successfully</h5>
                        {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={openSucessModal}>
                            <span aria-hidden="true">&times;</span>
                        </button> */}
                    </div>
                    <div className="mh-30">
                        <img src={SuccessCheckIcon} alt="success check icon" className="img-thumbnail rounded mx-auto d-block" style={{height: "20vh"}} />
                        <h4 className="modal-title text-center" id="successModalLabel">Invoice Created Successfully</h4>
                        <h5 className="modal-title text-center" id="successModalLabel">Invoice Number: {invoiceNumber} </h5>

                    </div>
                    <div className="d-none">
                        <PrintInvoice invoiceId={invoiceId} ref={printRef} />
                    </div>
                    <div className="bottom-modal-btns modal-body d-grid justify-content-center gap-5" >
                        {/* <p>Your invoice has been created successfully.</p> */}
                        <button type="button" className="btn btn-secondary" onClick={handleDownloadPDF}>Download</button>
                        <button type="button" className="btn btn-primary" onClick={handlePrint}>Print</button>
                        <button type="button" className="btn btn-danger" onClick={openSucessModal}>Close</button>
                    </div>
                </div>
            </div>
        </div>

    )}

        </div>
    );
}

export default CreateInvoice;