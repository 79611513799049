import React, { useState } from "react";
import "./App.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import SideNav from "./components/SideNav";
import AppRoute from "./components/AppRoute/Index";
import store from "./Store/Store";
import { Provider } from "react-redux";
import { useLocation } from "react-router-dom";
import { Toaster } from "sonner";


function App() {
    const location = useLocation();
    const hideSideNav = location.pathname === "/login" || location.pathname === "/register";
    return (
        <Provider store={store}>
            <div className="wrapper">
                <Header />
                <AppRoute />
                <Footer />
                {!hideSideNav && <SideNav />}
                <Toaster position="top-right" richColors  />
            </div>
        </Provider>
    );
}

export default App;