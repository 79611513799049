import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import PaymentTable from "../../CommonComp/invoiceandpaymenttable/PaymentTable";
import PrintInvoice from "./PrintInvoice";
import { API_URL } from "../../CommonComp/APIConstants";
import "./invoicespage.css";

function Invoices() {
    const navigate = useNavigate();
    const componentRef = useRef();
    const [invoices, setInvoices] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [isPrintReady, setIsPrintReady] = useState(false);
    const [downloadRequested, setDownloadRequested] = useState(false);
    const invoicesPerPage = 12;

    const triggerPrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => setSelectedInvoiceId(null),
    });

    const formatAmount = (amount) => {
        return typeof amount === 'number'
            ? amount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })
            : amount;
    };

    const getPaymentStatus = (status) => {
        switch (status) {
            case 0: return { text: "Pending", color: "red" };
            case 1: return { text: "Partial Paid", color: "orange" };
            case 2: return { text: "Fully Paid", color: "green" };
            case 3: return { text: "Settled", color: "blue" };
            default: return { text: "Unknown", color: "black" };
        }
    };

    useEffect(() => {
        setLoading(true);
        axios.post(`${API_URL}/Invoice/GetInvoiceList`)
            .then(response => {
                setInvoices(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("There was an error fetching the invoice data!", error);
                setLoading(false);
            });
    }, []);

    const filteredInvoices = invoices.filter(invoice =>
        invoice.customerName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        invoice.invoiceNumber?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const indexOfLastInvoice = currentPage * invoicesPerPage;
    const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
    const currentInvoices = filteredInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);

    const totalPages = Math.ceil(filteredInvoices.length / invoicesPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleRowClick = (index) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };

    const handlePrint = (invoiceId) => {
        setSelectedInvoiceId(invoiceId);
        triggerPrint();
        setIsPrintReady(false);
    };

    const handleDownload = (invoiceId) => {
        setSelectedInvoiceId(invoiceId);
        setDownloadRequested(true);
        setIsPrintReady(false);
    };

    useEffect(() => {
        if (selectedInvoiceId && isPrintReady) {
            if (downloadRequested) {
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'mm',
                    format: [650, 850],
                });

                const htmlContent = componentRef.current.innerHTML;

                pdf.html(htmlContent, {
                    callback: function (pdf) {
                        pdf.save(`invoice_${selectedInvoiceId}.pdf`);
                        setSelectedInvoiceId(null);
                        setDownloadRequested(false);
                        setIsPrintReady(false);
                    },
                    x: 10,
                    y: 10,
                    html2canvas: { scale: 1 },
                    margin: [10, 10, 10, 10],
                });
            } else {
                triggerPrint();
            }
        }
    }, [isPrintReady, selectedInvoiceId, downloadRequested, triggerPrint]);

    const handlePrintReady = () => {
        setIsPrintReady(true);
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h2>Invoices</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body" style={{ minHeight: "530px", position: "relative" }}>
                                    <div className="row">
                                        <div className="col-md-11">
                                            <input
                                                type="text"
                                                placeholder="Search by customer name or invoice number..."
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                                className="form-control"
                                                style={{ maxWidth: '300px', display: 'inline-block' }}
                                            />
                                        </div>
                                        <div className="col-md-1">
                                            <button style={{ marginBottom: '10px' }} type="button" onClick={() => navigate("/create_invoice")} className="btn btn-block btn-success">Add</button>
                                        </div>
                                    </div>

                                    {loading ? (
                                        <div className="loading-spinner">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <PaymentTable
                                            currentInvoices={currentInvoices}
                                            indexOfFirstInvoice={indexOfFirstInvoice}
                                            indexOfLastInvoice={indexOfLastInvoice}
                                            filteredInvoices={filteredInvoices}
                                            expandedRowIndex={expandedRowIndex}
                                            setExpandedRowIndex={setExpandedRowIndex}
                                            setCurrentPage={setCurrentPage}
                                            totalPages={totalPages}
                                            currentPage={currentPage}
                                            formatAmount={formatAmount}
                                            getPaymentStatus={getPaymentStatus}
                                            onPrint={handlePrint}
                                            onDownload={handleDownload}
                                            selectedInvoiceId={selectedInvoiceId}
                                            componentRef={componentRef}
                                            handlePrintReady={handlePrintReady}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {selectedInvoiceId && (
                <div style={{ display: 'none' }}>
                    <div ref={componentRef}>
                        <PrintInvoice invoiceId={selectedInvoiceId} onReady={handlePrintReady}  />
                    </div>
                </div>
            )}
        </>
    );
}

export default Invoices;
