import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ProductDropdown(props) {

    const [selectedProductId, setSelectedProductId] = useState('');

    function handleSelectChangeProduct(e) {
        console.log("Selected value");
        console.log("targetValue", e.target.value);
        const selectedProduct = props.data.find(option => option.productId === e.target.value);
        console.log(selectedProduct)


        props.addProductToList(selectedProduct);
    };

    return (
        <div className="form-group">
            <div className="input-group-append">
                <select
                    className="form-control select2"
                    style={{ width: '100%' }}
                    value={selectedProductId}
                    onChange={handleSelectChangeProduct}
                >
                    <option value="">Select Product</option>
                    {props.data.map((product) => (
                        <option key={product.productId} value={product.productId}>
                            {product.productName}
                        </option>
                    ))}
                </select>
                <button className="btn btn-sidebar" data-toggle="modal" data-target="#productModal-lg">
                    <i className="fas fa-search fa-fw" />
                </button>
            </div>
        </div>
    );
}

export default ProductDropdown;